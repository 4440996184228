import React from 'react';
import { graphql, Script } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import DealerList from '../components/DealerList/DealerList';
import Container from '../components/Layout/SharedStyles/Container';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import ContentWithButton from '../components/Content/ContentWithButton';

const DealerPages = ({
  data: {
    datoCmsDealerPage: { title, locations, id, metaTags, useStoreLocator, text, link, linkText },
    homeCrumb,
  },
  pageContext,
}) => {
  const isBrowser = () => typeof window !== 'undefined' && window;

  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  let filters = '';
  if (homeCrumb.locale == 'en-GB') {
    filters = '111015';
  } else if (homeCrumb.locale == 'en-AU') {
    filters = '110610,110611,110614,110615,110617';
  } else if (homeCrumb.locale == 'en-NZ') {
    filters = '110616';
  } else if (homeCrumb.locale == 'en-EU') {
    filters = '111560';
  }

  console.log('boomfa', homeCrumb.locale);
  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      <ContentWithButton
        text={text}
        link={link}
        linkText={linkText}
      />
      <Container width="medium" pt={2} pb={2}>
        {useStoreLocator ? (
          <div>
            <div class="storerocket-store-locator"></div>
            <Script
              src="//cdn.storerocket.io/widget.js"
              onLoad={() =>
                isBrowser &&
                window.StoreRocket.init({
                  selector: '.storerocket-store-locator',
                  account: 'BZJWa3rp0o',
                  filters: filters,
                })
              }
              onError={() => console.log('sadness')}
            />
          </div>
        ) : (
          <DealerList locations={locations} />
        )}
      </Container>
    </PageWrapper>
  );
};

export default DealerPages;

export const query = graphql`
  query DealerPageQuery($locale: String!) {
    datoCmsDealerPage(locale: { eq: $locale }) {
      locale
      title
      useStoreLocator
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
      locations {
        city
        address
        phone
        region
        title
        id: originalId
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fm: "webp", h: "474", fit: "crop", w: "732", q: 60 }
          )
          alt
        }
      }
      text
      linkText
      link {
        ... on DatoCmsHomepage {
          id: originalId
        }
        ... on DatoCmsContactPage {
          id: originalId
        }
      }
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
  }
`;
