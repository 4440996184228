import React from 'react';

const IconLocation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.76 29.76"
      width="20"
      height="20"
    >
      <path
        id="iconmonstr-map-8"
        fill="#3095b4"
        d="M14.88 0a7.4 7.4 0 0 0-7.44 7.07c0 5.8 5.93 6.42 7.44 15.25 1.5-8.83 7.44-9.45 7.44-15.25A7.4 7.4 0 0 0 14.88 0Zm0 9.92a2.48 2.48 0 1 1 2.48-2.48 2.48 2.48 0 0 1-2.48 2.48Zm14.88 19.84-8.32-3.01-6.56 3-6.92-3-7.96 3L4.96 18.6l4.91-1.96a14.1 14.1 0 0 1 1.32 2.14l-4.37 1.75-2.14 4.8 3.31-1.24 1.87-3.34-.79 3.78 5.2 2.21.6-2.5.59 2.5 5.14-2.33-.77-3.77 1.95 3.6 3.39 1.22-2.16-4.93-4.44-1.75a13.88 13.88 0 0 1 1.32-2.15l4.99 1.97Z"
      />
    </svg>
  );
};

export default IconLocation;
