import React from 'react';
import Container from '../Layout/SharedStyles/Container';
import Button from '../Button/Button';
import style from './content-with-button.mod.scss';

const ContentwithButton = ({ text, link, linkText }) => {
  if (text || link) {
    return (
      <Container pt={1} width={'medium'}>
        <div className={style.content_button}>
          {text && <p>{text}</p>}
          {link && (
            <Button primary size={'small'} recordId={link?.id}> {/* recordId={link} */}
              {linkText}
            </Button>
          )}
        </div>
      </Container>
    );
  }
  return null
};

export default ContentwithButton;