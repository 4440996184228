import React from 'react';

const IconPhone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Group_144"
      data-name="Group 144"
      viewBox="0 0 24 24.01"
      width="20"
      height="20"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Rectangle_44"
            fill="#3095b4"
            d="M0 0h24v24.01H0z"
            data-name="Rectangle 44"
          />
        </clipPath>
      </defs>
      <g id="Group_144-2" clip-path="url(#clip-path)" data-name="Group 144">
        <path
          id="Path_12218"
          fill="#3095b4"
          d="M13.65 19.2c.93-.94 1.85-1.86 2.75-2.8a1.65 1.65 0 0 1 1.68-.43c1.41.27 2.83.51 4.26.68 1.23.14 1.65.51 1.66 1.73v3.9c0 1.3-.48 1.75-1.78 1.73A22.6 22.6 0 0 1 .85 7.38a36.9 36.9 0 0 1-.83-5.74C-.1.51.48.01 1.64 0h4.12C6.77.02 7.2.47 7.34 1.46c.21 1.54.47 3.07.73 4.6a1.58 1.58 0 0 1-.46 1.55c-.94.9-1.03 1-1.94 1.89 1.25 3.18 4.96 6.93 8.81 8.89"
          data-name="Path 12218"
        />
      </g>
    </svg>
  );
};

export default IconPhone;
