import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import IconLocation from '../Layout/Icons/IconLocation';
import IconPhone from '../Layout/Icons/IconPhone';
import style from './dealer-card.mod.scss';
import Button from '../Button/Button';

const DealerCard = ({ locations, contactLink }) => {
  return (
    <div className={style.cardList}>
      {locations.map((location) => (
        <div className={style.card} key={location.id}>
          <div className={style.card__image}>
            <GatsbyImage
              image={location.image.gatsbyImageData}
              alt={location.image.alt || ''}
            />
          </div>
          <h4>{location.city}</h4>
          <p>{location.title}</p>
          <span className={style.card__address}>
            <IconLocation />
            <p>{location.address}</p>
          </span>
          {location.phone && (
            <span className={style.card__phone}>
              <IconPhone />
              <p>{location.phone}</p>
            </span>
          )}
          <Button recordId={contactLink} size={'small'}>
            {'Get in touch'}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default DealerCard;
