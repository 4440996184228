import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import DealerCard from '../Dealer/DealerCard';
import Select from '../Select/Select';
import style from './dealer-list.mod.scss';

const DealerList = ({ locations }) => {
  const [selectedFilter, setSelectedFilter] = useState('');

  const [filteredLocations, setfilteredLocations] = useState(locations);

  const uniqueRegions = [...new Set(locations.map((item) => item.region))];

  const getFilterSelect = (value) => {
    setSelectedFilter(value);
  };

  const handleFilter = () => {
    if (!selectedFilter) {
      setfilteredLocations(locations);
    } else {
      const newList = locations.filter((obj) => obj.region === selectedFilter);
      setfilteredLocations(newList);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [selectedFilter]);

  const contactPage = useStaticQuery(graphql`
  {
    datoCmsContactPage {
      originalId
    }
  }
`);

  return (
    <div className={style.dealer}>
      <div className={style.dealer__filter}>
        <h5>Filter by region</h5>
        <Select items={uniqueRegions} getFilterSelect={getFilterSelect} />
      </div>
      <DealerCard contactLink={contactPage.datoCmsContactPage.originalId} locations={filteredLocations} />
    </div>
  );
};

export default DealerList;
